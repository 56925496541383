<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col cols="12">
        <b-card-actions :no-actions="true" title="List Barang">
          <section class="d-flex justify-content-between">
            <section>
              <b-button v-if="allowCreate()" variant="primary" class="mb-2 mr-2"
                @click.prevent="$router.push({ name: 'route-barang-add' })"> Tambah </b-button>
              <b-button v-if="allowDelete() && showMassDelButton" variant="outline-danger" class="mb-2"
                @click.prevent="confirmMassDelete"> Hapus </b-button>
            </section>
            <section>
              <b-button variant="outline-primary" @click.prevent="exportBarang"> Export </b-button>
            </section>
          </section>
          <header>
            <b-row>
              <b-col md="2" sm="4" class="my-1">
                <b-form-group class="mb-0" label="Per Page" label-for="perPage" size="sm">
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="2" class="my-1">
                <b-form-group label="Filter" label-for="filterInput" class="mb-0" size="sm">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                      @keydown.enter.prevent="getData()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="getData()" size="sm">
                        Cari
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="2" class="my-1">
                <b-form-group label="Tabel" label-for="tabel" class="mb-0" label-size="sm">
                  <b-form-select v-model="tabel" size="sm" :options="tabeloption">
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </header>

          <!-- /form filter -->
          <b-table striped small hover responsive :per-page="perPage" :items="items" :fields="fields"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
            <!-- Stasiun Qurban -->
            <template #cell(harga_dasarsts)="{ item }">
              <b-form-input size="sm" v-model="item.varian_item[0].harga_dasar"
                @keydown.enter.prevent="ubahvarian(item)" />
            </template>
            <template #cell(harga_jualsts)="{ item }">
              <template v-if="item.harga_jual && item.harga_jual.length > 0">
                <b-form-input size="sm" v-model="item.harga_jual[0].share_penjual"
                  @keydown.enter.prevent="ubahhargajual(item)" />
              </template>
            </template>
            <template #cell(kodests)="{ item }">
              <b-form-input size="sm" v-model="item.varian_item[0].kode" @keydown.enter.prevent="ubahvarian(item)" />
            </template>
            <template #cell(variansts)="{ item }">
              <b-form-input size="sm" v-model="item.varian_item[0].varian" @keydown.enter.prevent="ubahvarian(item)" />
            </template>
            <template #cell(namasts)="{ item }">
              <b-form-input size="sm" v-model="item.nama" @keydown.enter.prevent="ubahnama(item)" />
            </template>
            <template #cell(margin)="{ item }">
              {{ formatRupiah(item.harga_jual[0].share_penjual - item.varian_item[0].harga_dasar) }}
            </template>
            <template #cell(show_detailsts)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
              </b-form-checkbox>
            </template>
            <!-- Check box for show detail -->
            <template #cell(show_detail)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
              </b-form-checkbox>
            </template>
            <!-- Detail -->
            <template #row-details="{ toggleDetails, item }">
              <!-- <b-card> -->
              <b-row v-if="tabel == 1" class="mb-1">
                <b-col>
                  <quill-editor v-model="item.deskripsi">

                  </quill-editor>
                </b-col>

              </b-row>
              <b-row class="mb-1" v-else>
                <b-col cols="8">
                  <b-table :fields="[
    { key: 'kode', label: 'Kode' },
    { key: 'varian', label: 'Varian' },
    { key: 'harga_dasar', label: 'Harga Dasar' },
    { key: 'harga_coret', label: 'Harga Coret' },
    { key: 'aksi', label: '#' },
  ]" striped small responsive :items="item ? item.varian_item : []" table-variant="primary">
                    <template #cell(harga_dasar)="{ item }">
                      {{ formatRupiah(item.harga_dasar) }}
                    </template>
                    <template #cell(harga_coret)="{ item }">
                      {{ formatRupiah(item.harga_coret) }}
                    </template>
                    <template #cell(kode)="{ item }">
                      {{ item.kode }}
                    </template>
                    <template #cell(varian)="{ item }">
                      {{ item.varian }}
                    </template>
                      <template v-if="modeedit == true" #cell(harga_dasar)="{ item }">
                        <b-form-input size="sm" v-model="item.harga_dasar" @keydown.enter.prevent="ubahvarianitem(item)" />
                      </template>
                      <template v-if="modeedit == true" #cell(varian)="{ item }">
                        <b-form-input size="sm" v-model="item.varian" @keydown.enter.prevent="ubahvarianitem(item)" />
                      </template>
                      <template v-if="modeedit == true" #cell(kode)="{ item }">
                        <b-form-input size="sm" v-model="item.kode" @keydown.enter.prevent="ubahvarianitem(item)" />
                      </template>
                      <template v-if="modeedit == true" #cell(harga_coret)="{ item }">
                        <b-form-input size="sm" v-model="item.harga_coret" @keydown.enter.prevent="ubahvarianitem(item)" />
                      </template>
                    <template #cell(aksi)="{ item }">
                      <section class="d-flex justify-content-end">
                        <b-button :id="`edit-btn-${item.barang_id}`" size="sm" @click.prevent="aturharga(item)"
                          class="mr-1" variant="outline-info" title="Atur Harga">
                          <!-- <feather-icon icon="EditIcon" /> --> Harga Jual
                        </b-button>
                      </section>
                    </template>
                  </b-table>
                </b-col>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="modeedit"
                  >
                    Mode Edit
                  </b-form-checkbox>
                </b-col>
                <b-col cols="3">
                  <table border="3">
                    <tr>
                      <!-- <th>No</th> -->
                      <th>Satuan Dari</th>
                      <th>Nilai</th>
                      <!-- <th>Satuan Barang</th> -->
                    </tr>
                    <tr v-for="(satuan, i) in item.konversi" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <!-- <td>{{ satuan.no }}</td> -->
                      <td>1 {{ satuan.satuan ? satuan.satuan.satuan : "-" }}</td>
                      <td>
                        {{ formatRupiah(satuan.nilai) }}
                      </td>
                      <!-- <td>
                    {{ formatRupiah(satuan.barang.satuan.satuan) }}
                  </td> -->
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-button v-if="tabel == 1" size="sm" variant="outline-success" @click="ubahnama(item)" class="mb-1 mr-1">
                Simpan </b-button>
              <b-button size="sm" variant="outline-danger" @click="toggleDetails" class="mb-1"> Hide </b-button>
              <!-- </b-card> -->
            </template>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(jenis)="{ item }">
              {{ item.jenis == 1 ? "Rokok" : "Non-Rokok" }}
            </template>
            <!-- <template #cell(harga_jual)="{ item }">
              {{ item.harga_jual && item.harga_jual.length > 0 ? formatRupiah(item.harga_jual[0].harga) : 0 }}
            </template> -->

            <template #cell(id_satuan)="{ item }">
              {{ item.satuan ? item.satuan.satuan : "-" }}
            </template>
            <template #cell(action)="{ item }">
              <section class="d-flex justify-content-end">
                <!-- <b-button v-if="item.varian_item.length > 1" :id="`edit-btn-${item.id}`" size="sm"
                  @click.prevent="aturharga(item)" class="mr-1" variant="outline-info" title="Atur Harga">
                  Varian
                </b-button> -->

                <b-button v-if="allowUpdate($route.meta.module)" :id="`edit-btn-${item.id}`" size="sm"
                  @click.prevent="$router.push(`/barang/edit/${item.id}`)" class="mr-1" variant="outline-info"
                  title="Edit Produk">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip> -->

                <b-button v-if="allowDelete($route.meta.module)" :id="`delete-btn-${item.id}`" size="sm"
                  @click.prevent="deleteSingle(item.id)" class="mr-1" variant="outline-danger" title="Hapus Produk">
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <!-- <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip> -->
              </section>
            </template>
          </b-table>
          <b-row>
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
          <b-modal v-model="showaturharga" id="modal-lg" size="lg" :title="itembarang ? itembarang.varian : ''"
            ok-title="Tutup" ok-variant="secondary" ok-only centered no-close-on-backdrop>
            <b-row>
              <b-col xl="6" lg="6" md="6" sm="12">
                <b-alert variant="danger" show>
                  <h4 class="alert-heading">
                    Contoh yang salah
                  </h4>
                  <div class="alert-body">
                    <span>Jangan ada data tipe konsumen dan satuan yang sama
                      <ol>
                        <li>Data Tipe Konsumen "Retail" dan Satuan "Pcs",</li>
                        <li>Data Tipe Konsumen "Retail" dan Satuan "Pcs",</li>
                        Untuk Data kedua jangan sampai ada data yg berisikan seperti diatas, harus berbeda satuannya
                      </ol>
                    </span>
                  </div>
                </b-alert>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12">
                <b-alert variant="success" show>
                  <h4 class="alert-heading">
                    Contoh yang benar
                  </h4>
                  <div class="alert-body">
                    <span>
                      <ol>
                        <li>Data Tipe Konsumen "Retail" dan Satuan "Pcs",</li>
                        <li>Data Tipe Konsumen "Retail" dan Satuan "Karung",</li>
                      </ol>

                    </span>
                  </div>
                </b-alert>
              </b-col>
              <b-col xl="12" lg="12" md="12" sm="12">
                <!-- <b-form-group label="Tipe Konsumen" label-for="tipekonsumen" class="mb-0" label-size="sm">
                  <b-form-select
                    v-model="form2.tipe_konsumen_id"
                    :options="tipe_konsumen"
                    :reduce="(option) => option.id"
                    label="nama"
                  >

                </b-form-select>
                </b-form-group> -->
                <b-button block @click.prevent="tambahhargajual()" class="mb-1" variant="primary"
                  title="Tambah Harga Jual">
                  <!-- <feather-icon icon="PlusIcon" /> --> Tambah Harga Jual
                </b-button>
                <b-modal v-model="kelolahargajual" id="modal-md" size="md" :title="itembarang ? itembarang.varian : ''"
                  ok-title="Tutup" ok-variant="secondary" ok-only centered no-close-on-backdrop>
                  <b-form>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Tipe Konsumen" label-for="h-tipe-konsumen" label-cols-md="4"><b-form-select
                            v-model="form2.tipe_konsumen_id" :options="tipe_konsumen" :reduce="(option) => option.id"
                            label="nama">
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Konversi Satuan" label-for="h-satuan" label-cols-md="4"><b-form-select
                            v-model="form2.satuan_id" :options="optsatuan" :reduce="(option) => option.id" label="nama">
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Payout" label-for="h-share_jasa" label-cols-md="4">
                          <b-form-input v-model="form2.share_jasa" id="h-share_jasa" placeholder="Payout" />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Harga Jual Mitra" label-for="h-share_penjual" label-cols-md="4">
                          <b-form-input v-model="form2.share_penjual" id="h-share_penjual"
                            placeholder="Harga Jual Mitra" />
                        </b-form-group>
                      </b-col>

                      <!-- submit and reset -->
                      <b-col offset-md="4">
                        <b-button @click="submithargajual()" variant="primary" class="mr-1">
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>

                  </b-form>
                </b-modal>

              </b-col>
              <b-col cols="12">
                <b-table :fields="[
    { key: 'tipe', label: 'Tipe Konsumen' },
    { key: 'satuan', label: 'Satuan' },
    { key: 'share_jasa', label: 'Payout' },
    { key: 'share_penjual', label: 'Harga Jual Mitra' },
    { key: 'harga', label: 'Harga Jual' },
    { key: 'harga_dasar', label: 'Harga Dasar' },
    { key: 'margin2', label: 'Margin' },
    { key: 'aksi', label: '#' },
  ]" small hover responsive :items="listhargajual ? listhargajual : []">
                  <template #cell(aksi)="{ item }">
                    <section class="d-flex justify-content-end">
                      <b-button :id="`edit-btn-${item.id}`" size="sm" @click.prevent="hapushargajual(item)" class="mr-1"
                        variant="outline-danger" title="Hapus Harga Jual">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </section>
                  </template>
                  <template #cell(tipe)="{ item, index }">
                    {{ item.tipe ? item.tipe.nama : '-' }}
                  </template>
                  <template #cell(satuan)="{ item, index }">
                    {{ item.satuan ? item.satuan.satuan : '-' }}
                  </template>
                  <template #cell(share_jasa)="{ item, index }">
                    <b-form-input v-model="item.share_jasa" type="text" @keyup="
    doFormatHarga({ index, item });
  hargaJual(index, item);
  " placeholder="Mark Up" @keydown.enter.prevent="ubahharga(item)" />
                  </template>
                  <template #cell(share_penjual)="{ item, index }">
                    <b-form-input v-model="item.share_penjual" type="text" @keyup="
    doFormatHarga({ index, item });
  hargaJual(index, item);
  " placeholder="Harga Beli Penjual" @keydown.enter.prevent="ubahharga(item)" />
                  </template>
                  <!-- <template #cell(harga)="{ item, index }">
              <b-form-input
                v-model="item.harga"
                type="text"
                @keyup="
                  doFormatHarga({ index, item });
                  hargaJual(index, item);
                "
                placeholder="Harga"
              />
            </template> -->
                  <template #cell(harga_dasar)="{ item, index }">
                    {{ item.harga_dasar }}
                  </template>
                  <template #cell(margin)="{ item, index }">
                    {{ formatRupiah(item.harga - item.harga_dasar) }}
                  </template>
                  <template #cell(margin2)="{ item }">
                    {{
    unFormatRupiah(item.harga) -
    unFormatRupiah(item.harga_dasar)
                    }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-modal>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import AturHarga from "./components/AturHarga.vue";
import service from "@/services";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BModal, BAlert, BForm, BOverlay, BRow, BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BCardText, BTable, BInputGroupAppend, BInputGroup, BTooltip, BFormCheckbox
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import DataBarang from "./components/DataBarang.vue";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'



export default {
  components: {
    BModal, BForm, BAlert,
    AturHarga,
    quillEditor,
    DataBarang,
    BCardText,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BInputGroupAppend,
    BInputGroup,
    BTooltip,
    BFormCheckbox,
  },
  data() {
    return {
      //Mode
      modeedit: false,
      //Atur Harga
      optsatuan: [],
      kelolahargajual: false,
      showaturharga: false,
      itembarang: null,
      itembarangvarian: null,
      form2: {
        varian_id: null,
        tipe_konsumen_id: null
      },
      konversiSatuanItem: [],
      listhargajual: [],
      //List Barang
      pageOptions: [10, 20, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      loaded: true,
      tabel: 0,
      tabeloption: null,
      fields: null,
      fields1: [
        { key: "show_detailsts", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "kodests", label: "Kode" },
        { key: "namasts", label: "Nama Barang", sortable: true },
        { key: "variansts", label: "Varian" },
        // { key: "harga_dasarsts", label: "Harga Dasar (Rp)", sortable: true },
        { key: "harga_jualsts", label: "Harga Jual (Rp)", sortable: true },
        // { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      fieldsDefault: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        // { key: "harga_jual", label: "Harga Jual", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      //Finance
      fields3: [
        { key: "show_detailsts", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "kodests", label: "Kode" },
        { key: "namasts", label: "Nama Barang", sortable: true },
        { key: "variansts", label: "Varian" },
        { key: "harga_dasarsts", label: "Harga Dasar (Rp)", sortable: true },
        { key: "margin", label: "Margin", sortable: true },
        { key: "harga_jualsts", label: "Harga Jual (Rp)", sortable: true },
        { key: "action", label: "#" },
      ],
      fields4: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "kodests", label: "Kode" },
        { key: "namasts", label: "Nama Barang", sortable: true },
        { key: "variansts", label: "Varian" },
        // { key: "harga_dasarsts", label: "Harga Dasar (Rp)", sortable: true },
        { key: "harga_jualsts", label: "Harga Jual (Rp)", sortable: true },
        // { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      loading: false,
      params: {
        order: "desc",
        // start: 0,
        // length: 10,
      },
      items: [],
      tipe_konsumen: [],
      showMassDelButton: false,
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    tabel(val) {
      if (val == 1) {
        this.fields = this.fields1
      } else if (val == 2) {
        this.fields = this.fieldsDefault
      } else if (val == 3) {
        this.fields = this.fields3
      } else if (val == 4) {
        this.fields = this.fields4
      } else if (val == 0 || null) {
        this.fields = this.fieldsDefault
      }
    }
  },
  methods: {
    hapushargajual(item) {
      this.$swal({
        title: 'Yakin hapus?',
        text: "Data ini akan terhapus jika klik 'Ya, Hapus'",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          try {
            this.$store.dispatch("hargajual/save", [
              {
                id: item.id,
                tipe_konsumen_id: item.tipe_konsumen_id,
                barang_id: item.barang_id,
                satuan_id: item.satuan_id,
                fungsi: 1,
              },
            ]);
            this.$swal({
              icon: 'success',
              title: 'Terhapus!',
              text: 'Data kamu berhasil terhapus',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.displaySuccess({
              message: "Harga berhasil terhapus",
            });
            this.gethargajual()
          } catch (e) {
            this.displayError(e);
            return false;
          }
        }
      })
      // this.$bvModal
      //   .msgBoxConfirm("Anda yakin akan menghapus barang ini? Data yang didalamnya akan hilang!", {
      //     title: "Konfirmasi Penghapusan",
      //     size: "sm",
      //     okVariant: "danger",
      //     okTitle: "Yes",
      //     cancelTitle: "No",
      //     cancelVariant: "outline-secondary",
      //     hideHeaderClose: false,
      //     centered: true,
      //   })
      //   .then((value) => {
      //     // this.boxTwo = value
      //     this.$store
      //       .dispatch("hargajual/save", [
      //         {
      //           id,
      //           fungsi: 1,
      //         },
      //       ])
      //       .then(() => this.gethargajual())
      //       .catch((e) => this.displayError(e));
      //   });
    },
    async submithargajual() {
      let payload = {
        tipe_konsumen_id: this.form2.tipe_konsumen_id,
        barang_id: this.itembarang.id,
        satuan_id: this.form2.satuan_id,
        share_jasa: this.unFormatRupiah(this.form2.share_jasa),
        share_penjual: this.unFormatRupiah(this.form2.share_penjual),
      };
      // if (this.form2.id) {
      //   payload.id = this.form2.id;
      // }
      try {
        await this.$store.dispatch("hargajual/save", [payload]);
        this.displaySuccess({
          message: "Harga berhasil disimpan",
        });
        this.kelolahargajual = false
        this.gethargajual()
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahhargajual(item1) {
      const ubah = item1.varian_item[0]
      const payload = {
        barang_utama: item1.id,
        nama: item1.nama,
        jenis: item1.jenis,
        jenis_obat: item1.jenis_obat,
        generik: item1.generik,
        narkotik: item1.narkotik,
        klasifikasi_usia: item1.klasifikasi_usia,
        id_kategori: item1.kategori ? item1.kategori.id : null,
        id_satuan: item1.satuan ? item1.satuan.id : null,
        milik: item1.milik,
        deskripsi: item1.deskripsi,
        kelompok_id: item1.kelompok_id,
        kondisi: item1.kondisi,
        min_pesanan: item1.min_pesanan,
        berat: item1.berat,
        panjang: item1.panjang,
        lebar: item1.lebar,
        tinggi: item1.tinggi,
        asuransi_pengiriman: item1.asuransi_pengiriman,
        is_consignment: item1.is_consignment == true ? 1 : 0,
        is_pre_order: item1.is_pre_order == true ? 1 : 0,
        pre_order_day: item1.pre_order_day == true ? 1 : 0,
        show_to_others: item1.show_to_others == true ? 1 : 0,
        kode: ubah.kode,
        varian: ubah.varian,
        harga_dasar: ubah.harga_dasar,
        item: [
          {
            barang_id: ubah.barang_id,
            varian: ubah.varian,
            kode: ubah.kode,
            harga_dasar: ubah.harga_dasar,
            no_batch: ubah.no_batch,
            expire_date: ubah.expire_date,
          }
        ],
      }
      try {
        await this.$store.dispatch("barang/save2", payload);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData()
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    tambahhargajual() {
      this.kelolahargajual = true
    },
    hargaJual(index, item) {
      let getSatuan = this.listhargajual[index];
      let harga =
        parseInt(this.unFormatRupiah(getSatuan.share_jasa)) +
        parseInt(this.unFormatRupiah(getSatuan.share_penjual));
      this.listhargajual[index].harga = this.formatRupiah(harga);
    },
    doFormatHarga({ index, item }) {
      // console.log(this.listhargajual[index]);
      this.listhargajual[index].share_jasa = this.formatRupiah(
        this.listhargajual[index].share_jasa
      );
      this.listhargajual[index].share_penjual = this.formatRupiah(
        this.listhargajual[index].share_penjual
      );
      const prices = Object.assign([], this.listhargajual);
      this.listhargajual = [];
      this.listhargajual = prices;
    },
    //Atur Harga
    aturharga(item) {
      this.itembarangvarian = item
      this.showaturharga = true
      this.getBarangId()
      // this.getTypeKonsumen()
    },
    async getBarangId() {
      let barang = await this.$store.dispatch(
        "barang/getDataByIdV2",
        this.itembarangvarian.barang_id
      );
      // console.log('AJI',barang)
      this.itembarang = barang
      this.gethargajual()
    },
    async gethargajual() {
      let params = {
        barang_id: this.itembarang.id,
        // tipe_konsumen_id: this.form2.tipe_konsumen_id,
      };
      let hargaJual = await this.$store.dispatch("hargajual/getData", params);
      hargaJual = JSON.parse(JSON.stringify(hargaJual));
      this.listhargajual = hargaJual
      this.getDataKonversi()
      this.getTypeKonsumen()
    },
    async getTypeKonsumen() {
      this.tipe_konsumen = [];
      const tipeKonsumen = await this.$store.dispatch("tipekonsumen/getData");
      tipeKonsumen.map((item) => {
        item.value = item.id;
        item.text = item.nama;
      });
      // var result = this.listhargajual.map(a => a.tipe.id);
      // const filterInPlace = (array, predicate) => {
      //   let end = 0;
      //   for (let i = 0; i < array.length; i++) {
      //     const obj = array[i];
      //     if (predicate(obj)) {
      //       array[end++] = obj;
      //     }
      //   }
      //   array.length = end;
      // };
      // const toDelete = new Set(result);
      // filterInPlace(tipeKonsumen, obj => !toDelete.has(obj.id));
      this.tipe_konsumen = tipeKonsumen;
      // console.log('jual', tipeKonsumen)
    },
    async getDataKonversi() {
      // const konversi = []
      const konversi = await this.$store.dispatch("konversi/getData", { id_barang: this.itembarang.id });
      // this.optsatuan = this.$store.state.konversi.datas;
      // konversi = JSON.parse(JSON.stringify(konversi));
      konversi.map((item) => {
        item.value = item.id;
        item.text = item.satuan;
      });
      // console.log('AJI',this.itembarang)
      var arr = [...konversi, { value: this.itembarang.satuan.id, text: this.itembarang.satuan.satuan }]
      // console.log('satuan', arr)
      this.optsatuan = arr
    },

    //List Barang
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    doFormatRupiah(i) {
      item[i].harga_dasar = this.formatRupiah(this.form.item[i].harga_dasar);
    },
    async ubahharga(item) {
      const payload = {
        id: item.id,
        tipe_konsumen_id: item.tipe_konsumen_id,
        barang_id: item.barang_id,
        harga: parseInt(this.unFormatRupiah(item.harga)),
        satuan_id: item.satuan ? item.satuan.id : item.satuan_id,
        share_jasa: parseInt(this.unFormatRupiah(item.share_jasa)),
        share_penjual: parseInt(this.unFormatRupiah(item.share_penjual)),
      }
      try {
        await this.$store.dispatch("hargajual/save", [payload]);
        this.displaySuccess({
          message: "Harga jual berhasil diperbarui",
        });
        this.getData()
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahvarianitem(item1) {
      // const ubah = item1.varian_item[0]
      const payload = {
        id: item1.barang_id,
        kode: item1.kode,
        varian: item1.varian,
        harga_dasar: this.unFormatRupiah(item1.harga_dasar),
        harga_coret: this.unFormatRupiah(item1.harga_coret),
      }
      try {
        await this.$store.dispatch("barang/save2", [payload]);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData()
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahvarian(item1) {
      const ubah = item1.varian_item[0]
      const payload = {
        barang_utama: item1.id,
        nama: item1.nama,
        jenis: item1.jenis,
        jenis_obat: item1.jenis_obat,
        generik: item1.generik,
        narkotik: item1.narkotik,
        klasifikasi_usia: item1.klasifikasi_usia,
        id_kategori: item1.kategori ? item1.kategori.id : null,
        id_satuan: item1.satuan ? item1.satuan.id : null,
        milik: item1.milik,
        deskripsi: item1.deskripsi,
        kelompok_id: item1.kelompok_id,
        kondisi: item1.kondisi,
        min_pesanan: item1.min_pesanan,
        berat: item1.berat,
        panjang: item1.panjang,
        lebar: item1.lebar,
        tinggi: item1.tinggi,
        asuransi_pengiriman: item1.asuransi_pengiriman,
        is_consignment: item1.is_consignment == true ? 1 : 0,
        is_pre_order: item1.is_pre_order == true ? 1 : 0,
        pre_order_day: item1.pre_order_day == true ? 1 : 0,
        show_to_others: item1.show_to_others == true ? 1 : 0,
        kode: ubah.kode,
        varian: ubah.varian,
        harga_dasar: ubah.harga_dasar,
        item: [
          {
            barang_id: ubah.barang_id,
            varian: ubah.varian,
            kode: ubah.kode,
            harga_dasar: ubah.harga_dasar,
            no_batch: ubah.no_batch,
            expire_date: ubah.expire_date,
          }
        ],
      }
      try {
        await this.$store.dispatch("barang/save2", payload);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData()
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahnama(item1) {
      const ubah = item1.varian_item[0]
      const payload = {
        barang_utama: item1.id,
        nama: item1.nama,
        jenis: item1.jenis,
        jenis_obat: item1.jenis_obat,
        generik: item1.generik,
        narkotik: item1.narkotik,
        klasifikasi_usia: item1.klasifikasi_usia,
        id_kategori: item1.kategori ? item1.kategori.id : null,
        id_satuan: item1.satuan ? item1.satuan.id : null,
        milik: item1.milik,
        deskripsi: item1.deskripsi,
        kelompok_id: item1.kelompok_id,
        kondisi: item1.kondisi,
        min_pesanan: item1.min_pesanan,
        berat: item1.berat,
        panjang: item1.panjang,
        lebar: item1.lebar,
        tinggi: item1.tinggi,
        asuransi_pengiriman: item1.asuransi_pengiriman,
        is_consignment: item1.is_consignment == true ? 1 : 0,
        is_pre_order: item1.is_pre_order == true ? 1 : 0,
        pre_order_day: item1.pre_order_day == true ? 1 : 0,
        show_to_others: item1.show_to_others == true ? 1 : 0,
        kode: ubah.kode,
        varian: ubah.varian,
        harga_dasar: ubah.harga_dasar,
        item: [
          {
            barang_id: ubah.barang_id,
            varian: ubah.varian,
            kode: ubah.kode,
            harga_dasar: ubah.harga_dasar,
            no_batch: ubah.no_batch,
            expire_date: ubah.expire_date,
          }
        ],
      }
      try {
        await this.$store.dispatch("barang/save2", payload);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData()
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    deleteSingle(id) {
      this.$bvModal
        .msgBoxConfirm("Anda yakin akan menghapus barang ini? Data yang didalamnya akan hilang!", {
          title: "Konfirmasi Penghapusan",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          // this.boxTwo = value
          this.$store
            .dispatch("barang/save", [
              {
                id,
                fungsi: 1,
              },
            ])
            .then(() => this.getData())
            .catch((e) => this.displayError(e));
        });
    },
    async clearExportedFile(filename) {
      try {
        const config = {
          url: `/clear-export`,
          method: "get",
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async print() {
      try {
        const config = {
          url: `/excel-data-barang`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return this.customRejectPromise(e);
      }
    },
    async exportBarang() {
      try {
        this.loading = true;

        const response = await this.print();
        await window.open(response);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    updatePage(val) {
      this.currentPage = val;
      this.getData();
    },
    getData() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        type: 'barang',
        product_type: 'barang',
        // filter_by: "nama_lengkap",
      };
      if (this.isCabang) {
        payload.member_id = this.user.karyawan.id;
      }
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          let items_total = this.$store.state.barang.totals;
          this.items = items;
          this.totalRows = items_total;
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    onItemChecked(payload) {
      if (payload.selectedRows.length > 0) {
        this.showMassDelButton = true;
      } else {
        this.showMassDelButton = false;
      }
    },
  },
  computed: {
    rows() {
      return this.items.total;
    },
    // Create an options list from our fields
    // sortOptions() {
    //   return this.fields
    //   .filter((f) => f.sortable)
    //   .map((f) => ({ text: f.label, value: f.key }));
    // },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getData();
    // this.getTypeKonsumen()
  },
  created() {
    if (this.user.karyawan.nama_lengkap == 'Stasiun Qurban') {
      this.tabel = 1
      this.tabeloption = [
        { value: 1, text: "Stasiun Qurban" },
        { value: 2, text: "Default" },
        // { value: 3, text: "Finance" },
        // { value: 4, text: "View 4" },
      ]
    } else if (this.isFinance) {
      this.tabel = 3
      this.tabeloption = [
        { value: 1, text: "Stasiun Qurban" },
        { value: 2, text: "Default" },
        { value: 3, text: "Finance" },
        { value: 4, text: "View 4" },
      ]
    } else {
      this.tabel = 2
      this.tabeloption = [
        // { value: 1, text: "Stasiun Qurban" },
        { value: 1, text: "Mode Ubah" },
        { value: 2, text: "Default" },
        // { value: 3, text: "Finance" },
        // { value: 4, text: "Default 2" },
      ]
    }
    // this.fields = this.fieldsDefault
    // this.getDataV2();
    // this.rows = barang.list;
  },
};
</script>
